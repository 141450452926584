export const IntrodoctionText = [
  'Julia "Rustered" Scott : Crossdressor with black sclera. It consists of physical body “Julia Scott” and “Liddle Beret Rustered Marshall” the phantom who controls the body.',
  "He loves Monstergirls. And he loves the virtual world where many extraordinary looking creatures exist.\n\
   But he knows that “the virtual world cannot exist without the resources of the real world”, so he decided to remain in the real world.",
];

export const CensoredText = [
  "“Liddle Beret Marshall” lost his life on ■■■■■■■■■ ■■, ■■■■. He was attacked by a horde of ruthless monsters called ■■■■■■.",
  'After his death, he meets a breathless man "■■ ■■". He gave his corpse to Liddle.',
  '■■ instructed Liddle to call the body “Julia Scott”. Liddle added to that name the middle name “Rustered”, a term he coined for “the man who was once rusted".',
];
